import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './Container.styles';

interface Props extends PropsWithClassName {
  children: React.ReactNode;
  fluid?: boolean;
}

const Container = (props: Props): React.ReactElement => {
  const { children, fluid } = props;
  return (
    <Root className={`${props.className} container${fluid ? '-fluid' : ''}`} data-testid={'Container-root'}>
      {children}
    </Root>
  );
};

export { Container };
