export enum TextWeight {
  Light = 'light',
  SemiBold = 'semibold',
}

export enum TextSize {
  Xs = 'xs',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum TextColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Link = 'link',
  Header = 'header',
  Error = 'error',
  ThemePrimary = 'themePrimary',
}

export enum TextAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
  Initial = 'initial',
  Inherit = 'inherit',
}

export enum TextTransform {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
  Capitalize = 'capitalize',
  Initial = 'initial',
  Inherit = 'inherit',
  Unset = 'unset',
}
