import styled from 'styled-components';

import ThemeModel from '@Api/models/ThemeModel';

interface RootProps {
  $oldTheme: ThemeModel | undefined;
  $newTheme: ThemeModel | undefined;
  $opacity: number;
  $isFluid: boolean;
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex: 1;
  padding: ${(props) => (props.$isFluid ? 0 : props.theme.space.xl_64)} 0;
  background: ${(props) => props.theme.palette.background.mainGradient.start};
  background: linear-gradient(
    180deg,
    ${(props) => props.$oldTheme?.colors?.backgroundGradientStart ?? props.theme.palette.background.mainGradient.start}
      0%,
    ${(props) => props.$oldTheme?.colors?.backgroundGradientEnd ?? props.theme.palette.background.mainGradient.end} 100%
  );

  &:before {
    opacity: ${(props) => props.$opacity};
    transition: opacity ${(props) => props.theme.transition.theme};
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      ${(props) => props.$newTheme?.colors?.backgroundGradientStart} 0%,
      ${(props) => props.$newTheme?.colors?.backgroundGradientEnd} 100%
    );
  }
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 10;
  width: 250px;
  height: auto;
`;
