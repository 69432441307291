'use client';

import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { TextColor, TextTransform } from '@Helpers/types/text';

import { H1, H2, H3, H4, H5 } from './Headline.styles';

export interface HeadlineProps extends PropsWithClassName {
  children: string | React.ReactNode;
  importance: 1 | 2 | 3 | 4 | 5;
  transform?: TextTransform;
  color?: TextColor;
}

const Headline = (props: HeadlineProps): React.ReactElement => {
  const { className, children, importance, transform = TextTransform.Unset, color = TextColor.Primary } = props;

  const testId = props.dataTestId ?? 'Headline-root';

  const renderHeadline = (importance: number): JSX.Element => {
    switch (importance) {
      case 1:
        return (
          <H1 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H1>
        );
      case 2:
        return (
          <H2 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H2>
        );
      case 3:
        return (
          <H3 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H3>
        );
      case 4:
        return (
          <H4 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H4>
        );
      case 5:
        return (
          <H5 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H5>
        );
      default:
        return (
          <H1 data-testid={testId} className={className} $transform={transform} $color={color}>
            {children}
          </H1>
        );
    }
  };

  return renderHeadline(importance);
};

export { Headline };
